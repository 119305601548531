
// globally used date format
export const DateFormat = 'dd.MM.yyyy'

// globally used date type
export type DateType = Date | null | undefined;
export type NullableDate = Date | null;

export function isValidDate(d: Date) {
    return d instanceof Date && !isNaN(d.getTime());
}

export function toLocalDate(value: string | undefined): string {
    if (value === undefined || value === null) {
        return 'N/A';
    } else {
        return new Date(value).toLocaleDateString();
    }
}

export function formatDate(value: Date | undefined): string {
    if (value === undefined || value === null) {
        return 'N/A';
    } else {
        return new Date(value).toLocaleDateString('cs-CZ');
    }
}

export function formatDateTime(value: Date | undefined): string {
    if (value === undefined || value === null) {
        return 'N/A';
    } else {
        return new Date(value).toLocaleString('cs-CZ');
    }
}

export function formatLicenseDate(value: Date | undefined | null): string {
    if (value === undefined || value === null) {
        return 'never';
    } else {
        return value.toISOString();
    }
}

export function sortComparer(date1?: Date, date2?: Date): number {

    if (date1 && date2) {
        return date1 >= date2 ? 1 : -1;
    }

    if (!date1 && !date2)
        return 0;

    return date1 ? 1 : -1;
}

// returns true if date 1 > date 2
export function dateComparer(date1?: Date, date2?: Date): boolean {

    if (date1 && date2) {
        return date1 > date2;
    }

    if (!date1 && !date2)
        return false;

    return date1 ? true : false;
}