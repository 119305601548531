// react
import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

// store
import { useStoreActions, useStoreState } from '../../hooks';

// routes
import routes from '../../routes';

// dto
import { DeviceBriefDto, LicenseType, ChecklistCompletionState } from '../../service/dataContract';

// mui
import { Badge, Chip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import SettingsIcon from '@material-ui/icons/Settings';
import MaterialTable, { Column } from 'material-table';

// utils
import { formatDate } from '../../utils/datetime';

// components
import { resolveCountry } from '../../model/codelist';

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        minWidth: 200,
    },
    content: {
        flexGrow: 0,
        padding: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(0.5),
    },
    toolbutton: {
        margin: theme.spacing(0.25),
    },

    searchContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: theme.spacing(4),
    },
    searchTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));


interface MatchParams {
    mode: string;
}

interface Props extends RouteComponentProps<MatchParams> {
}

export const Home: React.FC<Props> = props => {

    const classes = useStyles();
    const history = useHistory();

    const { isAdmin } = useStoreState(state => state.auth);
    const { devices, isLoading } = useStoreState(state => state.device);
    const { fetchDevices } = useStoreActions(state => state.device);

    function showDeleted() {
        return isAdmin && props.location.pathname === routes.deleted;
    }

    useEffect(() => {
        async function fetchData() {
            await fetchDevices({
                query: {
                    filterDeleted: showDeleted(),
                }
            });
        };

        fetchData();
    }, [fetchDevices]);


    // columns
    const columns: Column<DeviceBriefDto>[] = [
        { title: 'Type', field: 'deviceType.name', width: 150 },
        { title: 'S/N', field: 'serialNumber', width: 150 },
        {
            title: 'Country',
            field: 'contact.countryCode',
            customFilterAndSearch: (term, dto) => filterByCountry(term, dto),
            width: 100,
        },
        { title: 'Owner', field: 'contact.companyName' },
        {
            title: 'Sales Rep.',
            field: 'salesRepresentativeUserName',
            render: dto => renderSalesRep(dto),
        },
        { 
            title: 'Op. since', 
            field: 'operatingSince', 
            render: dto => formatDate(dto.operatingSince),
        },
        { title: 'Gauges mm', field: 'gaugeValues' },
        {
            title: 'License',
            render: dto => renderLicense(dto),
            customFilterAndSearch: (term, dto) => filterByLicenseType(term, dto),
            lookup: { 1: 'File', 2: 'KZV' },
        },
        {
            title: 'Touched',
            width: 150,
            field: 'lastModifiedAt',
            render: dto => {
                const username = dto.lastModifiedBy?.split('@')[0];
                return (
                    <div>
                        {username}
                        <br />
                        {formatDate(dto.lastModifiedAt)}
                    </div>
                );
            },
            editable: 'never',
            defaultSort: 'desc',
            export: false,
        },
    ];

    function renderLicense(dto: DeviceBriefDto): any {
        let hasFileLicense = (dto.fileLicenseCount ?? 0) > 0;
        let hasKzvLicense = (dto.kzvLicenseCount ?? 0) > 0;
        return (
            <div>
                {hasFileLicense && (<Chip size="small" color="primary" variant='outlined' label={LicenseType[LicenseType.File]} />)}
                {hasKzvLicense && (<Chip size="small" color="primary" variant='default' label={LicenseType[LicenseType.KZV]} />)}
            </div>
        );
    }

    function renderSalesRep(dto: DeviceBriefDto): any {

        let hasSalesRep = dto.salesRepresentativeUserName ? true : false;

        return (
            <div>
                {hasSalesRep && (<Chip size="small" color="primary" variant='outlined' label={dto.salesRepresentativeUserName} />)}
            </div>
        );
    }

    function filterByLicenseType(term: any, dto: DeviceBriefDto): term is LicenseType[] {

        let filter = (term as LicenseType[]);

        if (filter.length == 0)
            return true;

        var filterKZV = filter.some(value => value == LicenseType.KZV);
        var filterFile = filter.some(value => value == LicenseType.File);

        if (filterKZV && (dto.kzvLicenseCount ?? 0) > 0) {
            return true;
        }

        if (filterFile && (dto.fileLicenseCount ?? 0) > 0) {
            return true;
        }

        return false;
    }
  
    // country code filter
    // - filtering by iso code for 1..2 letters
    // - filtering by full name in en for more than 2 letters    
    function filterByCountry(term: any, dto: DeviceBriefDto): term is string {
        if (term) {

            const str = (term as string).toUpperCase();

            if (str.length <= 2) {
                return resolveCountry(dto.contact?.countryCode).code.toUpperCase().includes(str);
            }
            else {
                return resolveCountry(dto.contact?.countryCode).name.toUpperCase().includes(str);
            }

        } else {
            return false;
        }
    }

    function handleDetail(id: string) {
        history.push(routes.deviceDetail.formatPath(id))
    }

    return (
        <React.Fragment>
            <MaterialTable
                title="Device List"
                isLoading={isLoading}
                columns={columns}
                data={devices}
                options={{
                    filtering: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: 'kzv_devices_' + new Date().toLocaleDateString("en"),
                }}
                components={{
                }}
                actions={[
                    dto => ({
                        icon: () => (
                            <Badge variant='standard' badgeContent={dto.serviceOperationCount} color="secondary">
                                <SettingsIcon color='primary' />
                            </Badge>
                        ),
                        tooltip: `Device Detail ${dto.serviceOperationCount}`,
                        onClick: () => handleDetail(dto.id),
                        disabled: false,
                    }),
                ]}
            />
        </React.Fragment>
    );
}

export default Home;